import React, { useState, useEffect } from "react";
import axios from "axios";
import EventsNavbar from "../components/EventsNavbar";
import Footer from "../components/Footer";
import Line from "../assets/images/line.png";
import Blue_Marin from "../assets/images/blue-marlin.png";
import EventInformationBg from "../assets/images/event-informat-bg.png";
import { motion } from "framer-motion";
import Table from "../components/Table";

const WahooOpen = () => {
  const [loading, setLoading] = useState(false);
  const [wahooOpenData, setWahooOpenData] = useState(null);

  useEffect(() => {
    getTournamentRecord();
  }, []);

  const getTournamentRecord = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        "https://flyzone.ai/flyzone_laravel/api/tournaments",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        const wahooOpen = { day_1: [], day_2: [] };

        response.data.forEach((record) => {
          if (record.tournament_category === "Blue Marline Cove Wahoo Open") {
            console.log(record);
            if (record?.fish_weights.length > 0) {
              for (const fw of record.fish_weights) {
                const day = parseInt(fw.day_number, 10);
                if (isNaN(day) || day < 1 || day > 2) {
                  continue;
                }
                const dayKey = `day_${day}`;

                const totalWeight = fw.fish_weight.reduce((total, r) => {
                  if (r) {
                    return total + parseFloat(r);
                  } else {
                    return total;
                  }
                }, 0);

                const newRecord = {
                  id: record.id,
                  tournament_category: record.tournament_category,
                  boat_name: record.boat_name,
                  captain_name: record.captain_name,
                  email: record.email,
                  angler_name: record.angler_name,
                  fish_weights: fw.fish_weight,
                  base: record.base,
                  heaviest_10: record.heaviest_10,
                  daily_heaviest_fish: record.daily_heaviest_fish,
                  daily_aggregate: record.daily_aggregate,
                  overall_heavy_fish: record.overall_heavy_fish,
                  total_weight: parseFloat(totalWeight.toFixed(2)),
                };

                wahooOpen[dayKey].push(newRecord);
              }
            }
          }
        });

        const sortedWahooOpen = dataSortingAndCalculatiing(wahooOpen);
        setWahooOpenData(sortedWahooOpen);
      }
    } catch (err) {
      console.log("Error : ", err);
    }
    setLoading(false);
  };

  const dataSortingAndCalculatiing = (sortingAndCalculating) => {
    const getTotalWeightList = calculateTotalWaight(sortingAndCalculating);
    sortingAndCalculating["overall_weight"] = getTotalWeightList;

    for (const key in sortingAndCalculating) {
      const dataSorting = sortingRecordHandler(sortingAndCalculating[key]);
      sortingAndCalculating[key] = dataSorting;
    }

    return sortingAndCalculating;
  };

  const calculateTotalWaight = (data) => {
    const overallWeight = [];

    data.day_1.forEach((day1) => {
      const matchingDay2 = data.day_2.find(
        (day2) => day2.boat_name === day1.boat_name
      );

      const day1Total = day1.total_weight || 0;
      const day2Total = matchingDay2 ? matchingDay2.total_weight || 0 : 0;

      overallWeight.push({
        tournament_category: day1.tournament_category,
        boat_name: day1.boat_name,
        captain_name: day1.captain_name,
        email: day1.email,
        angler_name: day1.angler_name,
        base: day1.base,
        heaviest_10: day1.heaviest_10,
        daily_heaviest_fish: day1.daily_heaviest_fish,
        daily_aggregate: day1.daily_aggregate,
        overall_heavy_fish: day1.overall_heavy_fish,
        day_1_total: parseFloat(day1Total.toFixed(2)),
        day_2_total: parseFloat(day2Total.toFixed(2)),
        total_weight: parseFloat((day1Total + day2Total).toFixed(2)),
      });
    });

    data.day_2.forEach((day2) => {
      const existsInDay1 = data.day_1.find(
        (day1) => day1.boat_name === day2.boat_name
      );
      if (!existsInDay1) {
        overallWeight.push({
          tournament_category: day2.tournament_category,
          boat_name: day2.boat_name,
          captain_name: day2.captain_name,
          email: day2.email,
          angler_name: day2.angler_name,
          base: day2.base,
          heaviest_10: day2.heaviest_10,
          daily_heaviest_fish: day2.daily_heaviest_fish,
          daily_aggregate: day2.daily_aggregate,
          overall_heavy_fish: day2.overall_heavy_fish,
          day_1_total: 0,
          day_2_total: parseFloat(day2.total_weight.toFixed(2)) || 0,
          total_weight: parseFloat(day2.total_weight.toFixed(2)) || 0,
        });
      }
    });

    return overallWeight;
  };

  const sortingRecordHandler = (records) => {
    records.sort((a, b) => b.total_weight - a.total_weight);
    return records;
  };

  const touranmentDayRecordsTableColumn = [
    {
      Header: "Boat Name",
      accessor: "boat_name",
    },
    {
      Header: "Captain Name",
      accessor: "captain_name",
    },
    {
      Header: "Fish Weight.1",
      accessor: (row) => row.fish_weights?.[0] || "---",
    },
    {
      Header: "Fish Weight.2",
      accessor: (row) => row.fish_weights?.[1] || "---",
    },
    {
      Header: "Fish Weight.3",
      accessor: (row) => row.fish_weights?.[2] || "---",
    },
    {
      Header: "Fish Weight.4",
      accessor: (row) => row.fish_weights?.[3] || "---",
    },
    {
      Header: "Fish Weight.5",
      accessor: (row) => row.fish_weights?.[4] || "---",
    },
    {
      Header: "Total Weight (lbs)",
      accessor: (row) => row.total_weight || "---",
      id: "total_weight",
      sortType: "basic",
    },
  ];

  const totalWeightRecordTableColumn = [
    {
      Header: "Boat Name",
      accessor: "boat_name",
    },
    {
      Header: "Captain Name",
      accessor: "captain_name",
    },
    {
      Header: "Day 1",
      accessor: (row) => row.day_1_total || "---",
    },
    {
      Header: "Day 2",
      accessor: (row) => row.day_2_total || "---",
    },
    {
      Header: "Total Weight (lbs)",
      accessor: (row) => row.total_weight || "---",
      id: "total_weight",
      sortType: "basic",
    },
  ];

  console.log(wahooOpenData);

  return (
    <div>
      <section className="whoo-open-hero-section">
        <img className="wahoo-bg" src={EventInformationBg} alt="fish" />
        <EventsNavbar />
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="des-container">
                <h1 className="title">Blue Marlin Cove Wahoo Open</h1>
                <img src={Line} alt="line" className="responsive-line" />
                <p className="des ms-5">
                  <b style={{ fontWeight: "bold" }}>
                    January 16th to 18th, 2025
                  </b>
                </p>
                <p className="des">
                  Join us for the highly anticipated Blue Marlin Cove Wahoo
                  Open, a premier wahoo fishing tournament set against the
                  stunning backdrop of West End, Bahamas, from{" "}
                  <b style={{ fontWeight: "bold" }}>January 16-18.</b> This
                  exhilarating event invites anglers from near and far to
                  compete for glory and prizes in one of the most sought-after
                  fishing destinations in the world.
                  <br></br>
                  <br></br>
                  With an entry fee of{" "}
                  <b style={{ fontWeight: "bold" }}>$2,500</b>, participants
                  will have the chance to showcase their skills, compete with
                  fellow fishing enthusiasts, and experience the thrill of
                  reeling in trophy-sized wahoo. The tournament promises not
                  only fierce competition but also a vibrant atmosphere filled
                  with camaraderie and shared passion for the sport.
                  <br></br>
                  <br></br>
                  Whether you’re an experienced angler or a newcomer to wahoo
                  fishing, the Blue Marlin Cove Wahoo Open offers an
                  unforgettable experience amidst the breathtaking beauty of the
                  Bahamas. Don’t miss your chance to be part of this
                  extraordinary event!
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <motion.div
                className="img-container"
                initial={{ x: "100vw" }}
                animate={{ x: 0 }}
                transition={{ type: "spring", stiffness: 50 }}
              >
                <img src={Blue_Marin} alt="fish" />
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <section className="schedule-section">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="text-left mb-4">
                <p className="schedule-header">
                  Schedule Of <br />
                  Events
                </p>
              </div>
              <div className="section">
                <ul className="list-unstyled text-start d-flex flex-column gap-4">
                  <li className="d-flex align-items-center gap-2">
                    <div className="checkmark-circle">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span className="">
                      Thursday, January <b>16th 2025</b>
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-2">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>
                      Registration and Bag Pick up <b>4:00PM – 6:00PM</b>
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-2">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>
                      Captain’s Meeting <b>6:00PM</b>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="section-card">
                <h5 className="card-title">Day 1 Fishing</h5>
                <p className="text-muted">Friday, January 17th 2025</p>
                <ul className="list-unstyled text-start">
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>6:00 AM Boats may leave Blue Marlin Cove Marina</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>4:00 PM Boats must be checked into the Marina</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>3:00 PM Weigh Station Open</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="section-card">
                <h5 className="card-title">Day 2 Fishing</h5>
                <p className="text-muted">Saturday, January 18th 2025</p>
                <ul className="list-unstyled text-start">
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>6:00 AM Boats may leave Blue Marlin Cove Marina</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>4:00 PM Boats must be checked into the Marina</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>3:00 PM Weigh Station Open</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <p className="award-text">Award Dinner 6:00PM</p>
          </div>
        </div>
      </section>

      <section className="leader-board-section">
        <div className="container">
          {/* <h4>Leader Board</h4> */}
          {/* {!loading && (
            <div className="table-section mb-3">
              <div className="title">Overall Score Wahoo Open</div>
              <div className="my-2">
                {wahooOpenData && wahooOpenData?.totalWeight && (
                  <Table
                    columns={totalWeightRecordTableColumn}
                    data={wahooOpenData?.totalWeight}
                    isEditable={false}
                    isDeletable={false}
                  />
                )}
                {wahooOpenData && wahooOpenData.totalWeight.length === 0 && (
                  <div className="empty-table-row text-center">
                    The board will be updated during the event day
                  </div>
                )}
              </div>
            </div>
          )} */}
          {/* {!loading && (
            <div className="table-section mb-3">
              <div className="title">
                <b>Day 1 </b>: Friday 17th January 2025
              </div>
              <div className="my-2">
                {wahooOpenData && wahooOpenData?.day_1 && (
                  <Table
                    columns={touranmentDayRecordsTableColumn}
                    data={wahooOpenData?.day_1}
                    isEditable={false}
                    isDeletable={false}
                  />
                )}
                {wahooOpenData && wahooOpenData.day_1.length === 0 && (
                  <div className="empty-table-row">
                    The board will be updated during the event day
                  </div>
                )}
              </div>
            </div>
          )} */}
          {/* {!loading && (
            <div className="table-section mb-3">
              <div className="title">
                <b>Day 2</b> : Saturday 18th January 2025
              </div>
              <div className="my-2">
                {wahooOpenData && wahooOpenData?.day_2 && (
                  <Table
                    columns={touranmentDayRecordsTableColumn}
                    data={wahooOpenData?.day_2}
                    isEditable={false}
                    isDeletable={false}
                  />
                )}
                {wahooOpenData && wahooOpenData.day_2.length === 0 && (
                  <div className="empty-table-row text-center">
                    The board will be updated during the event day
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default WahooOpen;
