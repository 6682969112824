import React, { useState, useEffect } from "react";
import "../../assets/css/dashboard.css";
import axios from "axios";
import DashboardLayout from "../../components/DashboardLayout";
import EditIcon from "../../assets/images/editIcon.png";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getTokenFromLocalStorage } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RemoveIcon from "../../assets/images/remove-icon.png";
import AddTeamRecordModal from "./AddTeamRecord";
import TeamDeleteModel from "./DeleteTeamModel";

const LeaderBoardAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [showRecords, setShowRecords] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deletingRecordId, setDeletingRecordId] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const navigate = useNavigate();

  const handleEditClick = (record) => {
    setEditingRecord(record);
    setIsEditModalOpen(true);
  };
  const onAddClick = (record) => {
    setEditingRecord(null);
    setIsEditModalOpen(true);
  };

  const pageInitialization = async () => {
    await checkIsUserLoggedIn();
    await getTournamentRecord();
  };

  const checkIsUserLoggedIn = async () => {
    const tokenValidation = await getTokenFromLocalStorage();
    if (!tokenValidation.token) {
      localStorage.clear();
      navigate("/login");
    }
  };

  const getTournamentRecord = async () => {
    const token = await localStorage.getItem("authToken");
    setLoading(true);
    try {
      const response = await axios.get(
        "https://flyzone.ai/flyzone_laravel/api/tournaments",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setShowRecords(response?.data);
      }
    } catch (err) {
      toast.error("Something went wrong.");
    }
    setLoading(false);
  };

  useEffect(() => {
    pageInitialization();
    getTournamentRecord();
  }, []);

  const showDeleteModalHandler = (id) => {
    setDeletingRecordId(id);
    setShowConfirmDeleteModal(true);
  };
  const handleDeleteConfirmation = async (confirm) => {
    if (!confirm) {
      setShowConfirmDeleteModal(false);
      return;
    }
    try {
      const token = await localStorage.getItem("authToken");
      await axios.delete(
        `https://flyzone.ai/flyzone_laravel/api/tournaments/${deletingRecordId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      await getTournamentRecord();
      toast.success("Tournament record deleted successfully");
    } catch (error) {
      toast.error("Something went wrong.");
    }
    setShowConfirmDeleteModal(false);
    setDeletingRecordId(null);
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <div className="main-content">
        <div className="header">
          <div className="header-row">
            <h1>Registration Form</h1>
          </div>
        </div>

        <div className="content-section">
          <div className="d-flex">
            <div className="d-flex justify-content-start filter-row w-100">
              <div
                style={{
                  background: "",
                  border: "1px solid #0584FA",
                  borderRadius: "6px",
                  color: "black",
                }}
              >
                <button
                  style={{ color: "#0584FA" }}
                  className="btn c-btn"
                  onClick={() => onAddClick()}
                >
                  Add +
                </button>
              </div>
            </div>
          </div>

          <div className="leader_board_table_container">
            {loading ? (
              <div className="loading-overlay">
                <Spinner animation="border" role="status" className="spinner">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <table className="table m-0">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Team Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Captain Name</th>
                    <th scope="col">Boat Name</th>
                    <th scope="col">Angler Name</th>
                    <th scope="col">No. Team Members</th>
                    <th scope="col">Heaviest 10</th>
                    <th scope="col">Daily Heaviest Fish</th>
                    <th scope="col">Daily Aggregate</th>
                    <th scope="col">Overall Heaviest Fish</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {showRecords.length > 0 ? (
                    showRecords.map((record, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{record.team_name || "N/A"}</td>
                        <td>{record.email || "N/A"}</td>
                        <td>{record.captain_name || "N/A"}</td>
                        <td>{record.boat_name || "N/A"}</td>
                        <td>{record.angler_name || "N/A"}</td>
                        <td>{record.number_of_team_members || "N/A"}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={record.heaviest_10 || false}
                            className="custom-checkbox"
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={record.daily_heaviest_fish || false}
                            className="custom-checkbox"
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={record.daily_aggregate || false}
                            className="custom-checkbox"
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={record.overall_heavy_fish || false}
                            className="custom-checkbox"
                          />
                        </td>
                        <td className="image-pointer">
                          <img
                            onClick={() => handleEditClick(record)}
                            src={EditIcon}
                            alt="edit icon"
                            width={20}
                            height={20}
                            className=""
                          />
                        </td>
                        <td className="image-pointer">
                          <img
                            onClick={() => showDeleteModalHandler(record.id)}
                            src={RemoveIcon}
                            alt="remove icon"
                            width={25}
                            height={25}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <AddTeamRecordModal
        show={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={getTournamentRecord}
        record={editingRecord}
      />
      <TeamDeleteModel
        show={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteConfirmation}
      />
    </DashboardLayout>
  );
};

export default LeaderBoardAdmin;
