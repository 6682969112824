import React, { useState, useEffect } from "react";
import "../../assets/css/dashboard.css";
import axios, { all } from "axios";
import DashboardLayout from "../../components/DashboardLayout";
import PlusIcon from "../../assets/images/plus.png";
import EditIcon from "../../assets/images/editIcon.png";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getTokenFromLocalStorage } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddRecordModal from "./AddRecord";
import EditRecordModal from "./editModel";
import CustomDeleteModal from "./deleteModel";
import { backOut } from "framer-motion";
import { caption, rect, tr } from "framer-motion/client";

const LeaderBoard = () => {
  const [day, setDay] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tournament, setTournament] = useState("Blue Marline Cove Wahoo Open");
  const [coveChampionshipData, setCoveChampionshiData] = useState(null);
  const [allwahooOpenData, setAllWahooOpenData] = useState(null);
  const [dayWahooOpenData, setDayWahooOpenData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    pageInitialization();
  }, []);

  const pageInitialization = async () => {
    // await checkIsUserLoggedIn();
    await getTournamentRecord();
  };

  const checkIsUserLoggedIn = async () => {
    const tokenValidation = await getTokenFromLocalStorage();
    if (!tokenValidation.token) {
      localStorage.clear();
      navigate("/login");
    }
  };

  const getTournamentRecord = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        "https://flyzone.ai/flyzone_laravel/api/tournaments",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        const wahooOpen = { day_1: [], day_2: [] };
        const coveChampionship = { day_1: [], day_2: [] };

        response.data.forEach(async (record) => {
          if (record.tournament_category === "Blue Marline Cove Wahoo Open") {
            const data = {
              id: record.id,
              email: record.email,
              team_name: record.team_name,
              boat_name: record.boat_name,
              captain_name: record.captain_name,
              fish_weights: [],
              fish_weights_id: null,
            };

            if (record.fish_weights.length > 0) {
              let d1 = { ...data };
              let d2 = { ...data };

              record.fish_weights.forEach((fw) => {
                const day = parseInt(fw.day_number, 10);

                if (day === 1) {
                  d1 = {
                    ...d1,
                    fish_weights: fw.fish_weight,
                    fish_weights_id: fw.id,
                  };
                }

                if (day === 2) {
                  d2 = {
                    ...d2,
                    fish_weights: fw.fish_weight,
                    fish_weights_id: fw.id,
                  };
                }
              });

              wahooOpen.day_1.push(d1);
              wahooOpen.day_2.push(d2);
            } else {
              wahooOpen.day_1.push(data);
              wahooOpen.day_2.push(data);
            }
          } else if (
            record.tournament_category === "Blue Marline Cove Championship"
          ) {
          }
        });

        setAllWahooOpenData(wahooOpen);
        setDayWahooOpenData(wahooOpen?.day_1);
        // setCoveChampionshiData(coveChampionship);
      }
    } catch (err) {
      console.log("Error : ", err);
    }

    setLoading(false);
  };

  const addTournamentScoreHanlder = async (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const editTournamentScoreHanlder = (row) => {
    setSelectedRow(row);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleDropdownChange = (e) => {
    const value = e.target.options[e.target.selectedIndex].value;
    const name = e.target.name;

    if (name === "day") {
      setDay(value);

      if (tournament === "Blue Marline Cove Wahoo Open") {
        setDayWahooOpenData(allwahooOpenData[`day_${value}`]);
      }
      if (tournament === "Blue Marline Cove Championship") {
      }
    }

    if (name === "touranment") {
      setTournament(value);
    }
  };

  // const showDeleteModalHandler = (id) => {
  //   setDeletingRecordId(id);
  //   setShowConfirmDeleteModal(true);
  // };

  // const handleDeleteConfirmation = async (confirm) => {
  //   if (!confirm) {
  //     setShowConfirmDeleteModal(false);
  //     return;
  //   }

  //   try {
  //     const token = await localStorage.getItem("authToken");
  //     await axios.delete(
  //       `https://flyzone.ai/flyzone_laravel/api/tournaments/${deletingRecordId}`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );

  //     await getTournamentRecord();
  //     toast.success("Tournament record deleted successfully");
  //   } catch (error) {
  //     toast.error("Something went wrong.");
  //   }

  //   setShowConfirmDeleteModal(false);
  //   setDeletingRecordId(null);
  // };

  return (
    <DashboardLayout>
      <ToastContainer />
      <div className="main-content">
        {/* Top Header */}
        <div className="header">
          <div className="header-row">
            <h1>Leaderboard</h1>
          </div>
        </div>
        {/* Body */}
        <div className="content-section">
          <div className="d-flex justify-content-end filter-row w-100 gap-1">
            <div className="col-md-auto">
              <select
                id="dropdown"
                className="form-select custom-select"
                onChange={handleDropdownChange}
                name="day"
                value={day}
              >
                <option value="1">
                  Day 1 -
                  {tournament === "Blue Marline Cove Wahoo Open"
                    ? " 17th January 2025"
                    : " 14th February 2025"}
                </option>
                <option value="2">
                  Day 2 -
                  {tournament === "Blue Marline Cove Wahoo Open"
                    ? " 18th January 2025"
                    : " 15th February 2025"}
                </option>
              </select>
            </div>
            <div className="col-md-auto ms-1">
              <select
                id="dropdown"
                className="form-select custom-select"
                onChange={handleDropdownChange}
                name="touranment"
                value={tournament}
              >
                <option value="Blue Marline Cove Wahoo Open">
                  Blue Marline Cove Wahoo Open
                </option>
                <option value="Blue Marline Cove Championship">
                  Blue Marline Cove Championship
                </option>
              </select>
            </div>
          </div>

          <div className="leader_board_table_container">
            {loading ? (
              <div className="loading-overlay">
                <Spinner animation="border" role="status" className="spinner">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <table className="table m-0">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Boat Name</th>
                    <th scope="col">Captain Name</th>
                    <th scope="col">Fish Weight 1 (lbs)</th>
                    <th scope="col">Fish Weight 2 (lbs)</th>
                    <th scope="col">Fish Weight 3 (lbs)</th>
                    <th scope="col">Fish Weight 4 (lbs)</th>
                    <th scope="col">Fish Weight 5 (lbs)</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    tournament === "Blue Marline Cove Wahoo Open" &&
                    dayWahooOpenData.map((record, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{record.boat_name}</td>
                          <td>{record.captain_name}</td>
                          <td>{record.fish_weights[0] || "----"}</td>
                          <td>{record.fish_weights[1] || "----"}</td>
                          <td>{record.fish_weights[2] || "----"}</td>
                          <td>{record.fish_weights[3] || "----"}</td>
                          <td>{record.fish_weights[4] || "----"}</td>
                          <td className="image-pointer">
                            {record.fish_weights.length > 0 ? (
                              <img
                                onClick={() =>
                                  editTournamentScoreHanlder(record)
                                }
                                src={EditIcon}
                                alt="edit icon"
                                className="ms-1"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <img
                                onClick={() =>
                                  addTournamentScoreHanlder(record)
                                }
                                src={PlusIcon}
                                alt="remove icon"
                                width={20}
                                height={20}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <AddRecordModal
        show={isModalOpen}
        day={day}
        boatName={selectedRow?.boat_name}
        captainName={selectedRow?.captain_name}
        selectedRow={selectedRow}

        isEditMode={isEditMode}
        onSave={getTournamentRecord}
        onClose={ () => {
          setIsEditMode(false);
          setIsModalOpen(false);
          setSelectedRow(null);
          // await getTournamentRecord();
        }}
      />
      {/* <AddRecordModal
        show={isEditModalOpen}
        onClose={async () => {
          setIsEditModalOpen(false);
          await getTournamentRecord();
        }}
        onSubmit={async () => {
          await getTournamentRecord();
          setIsEditModalOpen(false);
        }}
        day={day}
        tournament={tournament}
        record={editingRecord}
      /> */}

      {/*<CustomDeleteModal
        show={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteConfirmation}
      /> */}
    </DashboardLayout>
  );
};

export default LeaderBoard;
