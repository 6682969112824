import { useTable, useSortBy } from "react-table";
import sortIcon from "../assets/images/sorting.png";
import "../assets/css/table.css";

const Table = ({ columns, data, isEditable = false, isDeletable = false }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <div className="container">
      <div className="table-responsive">
        <table className="table custom-table text-center" {...getTableProps()}>
          <thead className="thead-dark">
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(
                      column.id === "total_weight"
                        ? column.getSortByToggleProps()
                        : {}
                    )}
                    className="align-middle"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {column.render("Header")}
                      {column.id === "total_weight" && (
                        <img
                          className="ms-2"
                          src={sortIcon}
                          alt={column.isSortedDesc ? "sort-desc" : "sort-asc"}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      {...cell.getCellProps()}
                      className="align-middle"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
