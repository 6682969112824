import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import GetService from "../components/GetService";
import Blue from "../assets/images/blue-marlin.png";
import Camp from "../assets/images/camp.png";
import { Link } from "react-router-dom";
import MiniNavbar from "../components/MiniNavbar";
import { motion } from "framer-motion";

export default function Event() {
  return (
    <div>
      <MiniNavbar />
      <MainNavbar />
      {/* Our About */}
      <div className="container mt-5">
        <div className="container d-flex mt-5 flex-column flex-lg-row gap-5">
          <div className="hero-left">
            <h1 className="champ-h1 ">Blue Marlin Cove Wahoo Open</h1>

            <p className="hero-p">
              Fly Zone Fishing presents the Blue Marlin Cove Wahoo Open and
              Championship, an exciting series set to bring together top anglers
              for a competitive and engaging experience. The Wahoo Open will
              take place from January 16th to 18th, 2025, followed by the
              Championship on February 13th to 15th, 2025. This tournament
              promises a thrilling competition, showcasing talent and
              sportsmanship, while delivering an unforgettable experience for
              all participants.
            </p>
            <div className="row ">
              <ul className="list-unstyled text-start d-flex flex-column gap-4">
                <li className="d-flex align-items-center gap-2">
                  <div className="checkmark-circle">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                        fill="url(#paint0_linear_47_3299)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_47_3299"
                          x1="15.9999"
                          y1="31.0538"
                          x2="15.9999"
                          y2="1.48336e-09"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00B7FE" />
                          <stop offset="1" stop-color="#5823FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong className="">
                    Thursday, January 16th to 18th 2025
                  </strong>
                </li>
                <li className="d-flex align-items-center">
                  <div className="checkmark-circle me-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                        fill="url(#paint0_linear_47_3299)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_47_3299"
                          x1="15.9999"
                          y1="31.0538"
                          x2="15.9999"
                          y2="1.48336e-09"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00B7FE" />
                          <stop offset="1" stop-color="#5823FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong>Registration and Bag Pick up 4:00PM–6:00PM</strong>
                </li>
                <li className="d-flex align-items-center">
                  <div className="checkmark-circle me-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                        fill="url(#paint0_linear_47_3299)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_47_3299"
                          x1="15.9999"
                          y1="31.0538"
                          x2="15.9999"
                          y2="1.48336e-09"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00B7FE" />
                          <stop offset="1" stop-color="#5823FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong>Captain’s Meeting 6:00PM</strong>
                </li>
              </ul>
            </div>
            <Link
              className="text-decoration-none text-white"
              to="/events/wahoo"
            >
              <button className="contact-button">More Information</button>
            </Link>
          </div>
          <div className="" style={{ marginTop: "150px" }}>
            <motion.img
              src={Blue}
              alt="fish"
              width="500"
              initial={{ x: "100%" }}
              animate={{ x: "0%" }}
              transition={{
                type: "spring",
                stiffness: 50,
                damping: 20,
                duration: 2,
              }}
            />
          </div>
        </div>
        <hr></hr>
        <div className="container d-flex mt-5 flex-column flex-lg-row gap-5">
          <div className="" >
            <motion.img
              src={Camp}
              alt="fish"
              width="450"
              initial={{ x: "-100%" }}
              animate={{ x: "0%" }}
              transition={{
                type: "spring",
                stiffness: 50,
                damping: 20,
                duration: 2,
              }}
            />
          </div>
          <div className="hero-left">
            <h1 className="champ-h1 ">Blue Marlin Cove Championship</h1>

            <p className="hero-p">
              Fly Zone Fishing presents the Blue Marlin Cove Wahoo Open and
              Championship, an exciting series set to bring together top anglers
              for a competitive and engaging experience. The Wahoo Open will
              take place from January 16th to 18th, 2025, followed by the
              Championship on February 13th to 15th, 2025. This tournament
              promises a thrilling competition, showcasing talent and
              sportsmanship, while delivering an unforgettable experience for
              all participants.
            </p>
            <div className="row ">
              <ul className="list-unstyled text-start d-flex flex-column gap-4">
                <li className="d-flex align-items-center gap-2">
                  <div className="checkmark-circle">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                        fill="url(#paint0_linear_47_3299)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_47_3299"
                          x1="15.9999"
                          y1="31.0538"
                          x2="15.9999"
                          y2="1.48336e-09"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00B7FE" />
                          <stop offset="1" stop-color="#5823FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong className=""> February 13th to 15th 2025</strong>
                </li>
                <li className="d-flex align-items-center">
                  <div className="checkmark-circle me-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                        fill="url(#paint0_linear_47_3299)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_47_3299"
                          x1="15.9999"
                          y1="31.0538"
                          x2="15.9999"
                          y2="1.48336e-09"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00B7FE" />
                          <stop offset="1" stop-color="#5823FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong>Registration and Bag Pick up 4:00PM-6:00PM </strong>
                </li>
                <li className="d-flex align-items-center">
                  <div className="checkmark-circle me-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                        fill="url(#paint0_linear_47_3299)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_47_3299"
                          x1="15.9999"
                          y1="31.0538"
                          x2="15.9999"
                          y2="1.48336e-09"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00B7FE" />
                          <stop offset="1" stop-color="#5823FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong>Captain's Meeting 6:00PM </strong>
                </li>
              </ul>
            </div>
            <Link
              className="text-decoration-none text-white"
              to="/events/wahoo"
            >
              <button className="contact-button">More Information</button>
            </Link>
          </div>
        </div>
        {/* Get Service */}
        <GetService />
      </div>

      <Footer />
    </div>
  );
}
