import React from "react";
import "../assets/css/dashboard.css";
import Logo from "../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); 

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/login");
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="dashboard-page">
      <div className="dashboard">
        <div className="sidebar">
          <div className="sidebar-container">
            <a href="/" className="logo-link">
              <img src={Logo} alt="Logo" className="logo" />
            </a>
            <ul>
              <li>
                <Link
                  to="/admin-dashboard/leaderboard"
                  className={isActive("/admin-dashboard/leaderboard") ? "active-link" : ""}
                >
                  Leaderboard
                </Link>
              </li>
              <hr />
              <li>
                <Link
                  to="/admin-dashboard/registerform"
                  className={isActive("/admin-dashboard/registerform") ? "active-link" : ""}
                >
                  Registration Form
                </Link>
              </li>
              <li>
                <button
                  onClick={logoutHandler}
                  className="btn"
                  style={{ backgroundColor: "#007BFF", color: "white" }}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
