import React, { useState, useEffect } from "react";
import EventsNavbar from "../components/EventsNavbar";
import Footer from "../components/Footer";
import Line from "../assets/images/line.png";
import Camp from "../assets/images/camp.png";
import EventInformationBg from "../assets/images/event-informat-bg.png";
import { motion } from "framer-motion";
import axios from "axios";
import Table from "../components/Table";

const ChampionShip = () => {
  const [loading, setLoading] = useState(false);
  const [championShipData, setChampionShipData] = useState(null);

  useEffect(() => {
    getTournamentRecord();
  }, []);

  const getTournamentRecord = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        "https://flyzone.ai/flyzone_laravel/api/tournaments",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        const championShip = { day_1: [], day_2: [] };

        response.data.forEach((record) => {
          const day = parseInt(record.day_number, 10);

          const totalWeight = record.fish_weight.reduce((total, r) => {
            if (r) {
              return total + parseInt(r);
            } else {
              return total;
            }
          }, 0);

          const newRecord = {
            ...record,
            totalWeight: totalWeight,
          };

          if (record.tournament_category === "Blue Marline Cove Championship") {
            championShip[`day_${day}`].push(newRecord);
          }
        });

        const sortedWahooOpen = dataSortingAndCalculatiing(championShip);
        setChampionShipData(sortedWahooOpen);
      }
    } catch (err) {
      console.log("Error : ", err);
    }
    setLoading(false);
  };

  const dataSortingAndCalculatiing = (sortingAndCalculating) => {
    const getTotalWeightList = calculateTotalWaight(sortingAndCalculating);
    sortingAndCalculating["totalWeight"] = getTotalWeightList;

    for (const key in sortingAndCalculating) {
      const dataSorting = sortingRecordHandler(sortingAndCalculating[key]);
      sortingAndCalculating[key] = dataSorting;
    }

    return sortingAndCalculating;
  };

  const calculateTotalWaight = (data) => {
    const overallWeight = [];

    data.day_1.forEach((day1) => {
      const matchingDay2 = data.day_2.find(
        (day2) => day2.boat_name === day1.boat_name
      );
      const day1Total = day1.totalWeight || 0;
      const day2Total = matchingDay2 ? matchingDay2.totalWeight || 0 : 0;

      overallWeight.push({
        boat_name: day1.boat_name,
        captain_name: day1.caption_name,
        day1Total: day1Total,
        day2Total: day2Total,
        totalWeight: day1Total + day2Total,
      });
    });

    data.day_2.forEach((day2) => {
      const existsInDay1 = data.day_1.find(
        (day1) => day1.boat_name === day2.boat_name
      );
      if (!existsInDay1) {
        overallWeight.push({
          boat_name: day2.boat_name,
          captain_name: day2.caption_name,
          day1Total: 0,
          day2Total: day2.totalWeight || 0,
          totalWeight: day2.totalWeight || 0,
        });
      }
    });

    return overallWeight;
  };

  const sortingRecordHandler = (records) => {
    records.sort((a, b) => b.totalWeight - a.totalWeight);
    return records;
  };

  const touranmentDayRecordsTableColumn = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Boat Name",
      accessor: "boat_name",
    },
    {
      Header: "Captain Name",
      accessor: "caption_name",
    },
    {
      Header: "Fish Weight.1",
      accessor: (row) => row.fish_weight?.[0] || "---",
    },
    {
      Header: "Fish Weight.2",
      accessor: "fish_weight[1]",
      accessor: (row) => row.fish_weight?.[1] || "---",
    },
    {
      Header: "Fish Weight.3",
      accessor: (row) => row.fish_weight?.[2] || "---",
    },
    {
      Header: "Fish Weight.4",
      accessor: (row) => row.fish_weight?.[3] || "---",
    },
    {
      Header: "Fish Weight.5",
      accessor: (row) => row.fish_weight?.[4] || "---",
    },
    {
      Header: "Total Weight",
      accessor: (row) => row.totalWeight || "---",
      id: "totalWeight",
      sortType: "basic",
    },
  ];

  const totalWeightRecordTableColumn = [
    {
      Header: "Id",
      accessor: (row, i) => i + 1,
    },
    {
      Header: "Boat Name",
      accessor: "boat_name",
    },
    {
      Header: "Captain Name",
      accessor: "captain_name",
    },
    {
      Header: "Day 1",
      accessor: (row) => row.day1Total || "---",
    },
    {
      Header: "Day 2",
      accessor: (row) => row.day2Total || "---",
    },
    {
      Header: "Total Weight",
      accessor: (row) => row.totalWeight || "---",
      id: "totalWeight",
      sortType: "basic",
    },
  ];

  return (
    <div>
      <section className="championship-hero-section">
        <img className="championship-bg" src={EventInformationBg} alt="fish" />
        <EventsNavbar />
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="des-container">
                <h1 className="title">Blue Marlin Cove Championship</h1>
                <img
                  className="img-line"
                  src={Line}
                  alt="line"
                  class="responsive-line"
                />
                <p className="des ms-5">
                  <b style={{ fontWeight: "bold" }}>
                    February 13th thru 15th, 2025
                  </b>
                </p>
                <p className="des">
                  Get ready for an exhilarating fishing experience at the 2025
                  Blue Marlin Cove Wahoo Championship, taking place from{" "}
                  <b style={{ fontWeight: "bold" }}>February 13 to 15 </b> in
                  the beautiful West End, Bahamas! This renowned event is set
                  against the pristine waters of the Bahamas, making it the
                  ideal setting for anglers to battle for the best wahoo catch.
                  With the promise of thrilling competition, outstanding prizes,
                  and an atmosphere filled with camaraderie, this is an event
                  not to be missed!.
                  <br></br>
                  <br></br>
                  Participants will compete for top honors and generous cash
                  awards, while enjoying daily weigh-ins, evening gatherings,
                  and the unparalleled hospitality that Blue Marlin Cove is
                  known for. Whether you're a seasoned competitor or a
                  passionate fishing enthusiast, this tournament provides an
                  unmatched opportunity to reel in monster wahoos, connect with
                  fellow anglers, and experience the warmth and beauty of the
                  West End.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <motion.div
                className="img-container"
                initial={{ x: "100vw" }}
                animate={{ x: 0 }}
                transition={{ type: "spring", stiffness: 50 }}
              >
                <img src={Camp} alt="fish" />
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="schedule-section">
        <div className="container py-5">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="text-left mb-4">
                <p className="schedule-header">
                  Schedule Of <br />
                  Events
                </p>
              </div>
              <div className="section">
                <ul className="list-unstyled text-start d-flex flex-column gap-4">
                  <li className="d-flex align-items-center gap-2">
                    <div className="checkmark-circle">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span className="">
                      Thursday, February <b>13th 2025</b>
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-2">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>
                      Registration and Bag Pick up <b>4:00PM - 6:00PM</b>
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-2">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>
                      Captain’s Meeting <b>6:00PM</b>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="section-card">
                <h5 className="card-title">Day 1 Fishing</h5>
                <p className="text-muted">Friday, February 14th 2025</p>
                <ul className="list-unstyled text-start">
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>6:00 AM Boats may leave Blue Marlin Cove Marina</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>4:00 PM Boats must be checked into the Marina</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>3:00 PM Weigh Station Open</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="section-card">
                <h5 className="card-title">Day 2 Fishing</h5>
                <p className="text-muted">Saturday, February 15th 2025</p>
                <ul className="list-unstyled text-start">
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>6:00 AM Boats may leave Blue Marlin Cove Marina</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>4:00 PM Boats must be checked into the Marina</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="checkmark-circle me-3">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z"
                          fill="url(#paint0_linear_47_3299)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_47_3299"
                            x1="15.9999"
                            y1="31.0538"
                            x2="15.9999"
                            y2="1.48336e-09"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00B7FE" />
                            <stop offset="1" stop-color="#5823FF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span>3:00 PM Weigh Station Open</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="text-center mt-5">
            <p className="award-text">Award Dinner 6:00PM</p>
          </div>
        </div>
      </section>
      {/* 
      <section className="leader-board-section">
        <div className="container">
          <h4>Leader Board</h4>
          {!loading && (
            <div className="table-section mb-3">
              <div className="title">Overall Score Championship</div>
              <div className="my-2">
                {championShipData && championShipData?.totalWeight && (
                  <Table
                    columns={totalWeightRecordTableColumn}
                    data={championShipData?.totalWeight}
                    isEditable={false}
                    isDeletable={false}
                  />
                )}
                {championShipData &&
                  championShipData.totalWeight.length === 0 && (
                    <div className="empty-table-row text-center">
                      The board will be updated during the event day
                    </div>
                  )}
              </div>
            </div>
          )}
          {!loading && (
            <div className="table-section mb-3">
              <div className="title">
                <b>Day 1 </b>: Friday 14th February 2025
              </div>
              <div className="my-2">
                {championShipData && championShipData?.day_1 && (
                  <Table
                    columns={touranmentDayRecordsTableColumn}
                    data={championShipData?.day_1}
                    isEditable={false}
                    isDeletable={false}
                  />
                )}
                {championShipData && championShipData.day_1.length === 0 && (
                  <div className="empty-table-row">
                    The board will be updated during the event day
                  </div>
                )}
              </div>
            </div>
          )}
          {!loading && (
            <div className="table-section mb-3">
              <div className="title">
                <b>Day 2</b> : Saturday 15th February 2025
              </div>
              <div className="my-2">
                {championShipData && championShipData?.day_2 && (
                  <Table
                    columns={touranmentDayRecordsTableColumn}
                    data={championShipData?.day_2}
                    isEditable={false}
                    isDeletable={false}
                  />
                )}
                {championShipData && championShipData.day_2.length === 0 && (
                  <div className="empty-table-row text-center">
                    The board will be updated during the event day
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section> */}
      <Footer />
    </div>
  );
};

export default ChampionShip;
