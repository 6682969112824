import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/css/login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { getTokenFromLocalStorage } from "../utils/helpers";

export default function Login() {
  // State for form fields and errors
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    checkIsUserLoggedIn();
  }, []);

  const checkIsUserLoggedIn = async () => {
    const tokenValidation = await getTokenFromLocalStorage();
    if (tokenValidation.token && tokenValidation.isValid) {
      navigate("/admin-dashboard/leaderboard");
    }
  };

  // Validate email format
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors on change
  };

  // Handle form submission
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    // Validation
    let hasError = false;
    if (!validateEmail(email)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email address" }));
      hasError = true;
    }
    if (password.length < 6) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must be at least 6 characters long",
      }));
      hasError = true;
    }
    if (hasError) return;

    setLoading(true);
    try {
      setLoading(true);
      const response = await axios.post(
        "https://flyzone.ai/flyzone_laravel/api/auth/login",
        {
          email,
          password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);
      if (response.data) {
        localStorage.setItem("authToken", response.data.token);
        navigate("/admin-dashboard/leaderboard");
      }
    } catch (error) {
      setLoading(true);
      if (error.response && error.response.data) {
        toast.error(
          error.response.data.message || "Login failed. Please try again."
        );
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="login-page">
      <ToastContainer />
      <div className="wrapper">
        <div className="title">Login</div>
        <form onSubmit={onSubmitHandler}>
          <div className="field">
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <label>Email Address</label>
            {errors.email && <small className="error">{errors.email}</small>}
          </div>
          <div className="field">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <label>Password</label>
            {errors.password && (
              <small className="error">{errors.password}</small>
            )}
          </div>
          <div className="content mt-3">
            <div className="checkbox">
              <input type="checkbox" id="remember-me" />
              <label htmlFor="remember-me">Remember me</label>
            </div>
          </div>
          <div className="field login-btn">
            <input type="submit" value={loading ? "Logging in..." : "Login"} />
          </div>
          {message && <p className="message">{message}</p>}
          <div className="signup-link">
            Go back <Link to="/">Fly Zone</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
