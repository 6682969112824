import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/AboutUs";
import Contact from "./pages/ContactUs";
import Solution from "./pages/OurSolutions";
import Event from "./pages/Event";
import Gallery from "./pages/Gallery";
import ChampionShip from "./pages/ChampionShip";
import Information from "./pages/Information";
import WahooOpen from "./pages/WahooOpen";
import Rules from "./pages/Rules";
import ResortAndMarina from "./pages/ResortAndMarina";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Registration from "./pages/AdmainPanel/Registration";
import InvoiceManagement from "./pages/AdmainPanel/CouponManagemen";
import TeamRegister from "./pages/AdmainPanel/TeamRegister";
import RegisterPageTable from './pages/RegisterFormTable'

// import UploadReceipt from "./pages/UploadReceipt";
import ScrollToTop from "./components/ScrollToTop";
import LeaderBoard from "./pages/LeaderBoard";
import LeaderBoardAdmin from "./pages/AdmainPanel/LeaderBoard";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-solutions" element={<Solution />} />
        <Route path="/events" element={<Event />} />
        <Route path="/leaderboard" element={<LeaderBoard />} />
        <Route path="/events/gallery" element={<Gallery />} />
        <Route path="/events/championship" element={<ChampionShip />} />
        <Route path="/events/wahoo" element={<Information />} />
        <Route path="/events/wahoo-open" element={<WahooOpen />} />
        <Route path="/registerformtable" element={<RegisterPageTable />} />

        <Route path="/events/rules" element={<Rules />} />
        <Route path="/events/resort-and-marina" element={<ResortAndMarina />} />
        {/* <Route path="/events/Register" element={<Register />} /> */}
        <Route path="/login" element={<Login />} />
        {/* <Route
          path="/admin-dashboard/registration"
          element={<Registration />} 
        /> */}
        {/* <Route
          path="/admin-dashboard/coupon-management"
          element={<InvoiceManagement />}
        /> */}
        <Route
          path="/admin-dashboard/leaderboard"
          element={<LeaderBoardAdmin />}
        />
        <Route
          path="/admin-dashboard/registerform"
          element={<TeamRegister />}
        />
        {/* <Route path="/upload-receipt" element={<UploadReceipt />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
