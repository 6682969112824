import React from "react";
import Facebook from "../assets/images/facebook-white-blue.svg";
import Linkedin from "../assets/images/linkedin-white-blue.svg";
import Whatsapp from "../assets/images/whatsapp-white-blue.svg";
import Twitter from "../assets/images/twitter-white-blue.svg";

const MiniNavbar = () => {
  return (
    <div class="top-narbar">
      <div className="container text-end">
        <a href="/" className="me-2">
          <img src={Facebook} alt="Facebook" width="20" />
        </a>

        <a href="/" className="me-2">
          <img src={Linkedin} alt="linkedin" width="20" />
        </a>

        <a href="/" className="me-2">
          <img src={Whatsapp} alt="Whatsapp" width="20" />
        </a>

        <a href="/" className="me-2">
          <img src={Twitter} alt="Twitter" width="20" />
        </a>
      </div>
    </div>
  );
};

export default MiniNavbar;
