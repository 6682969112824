import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import GetService from "../components/GetService";
import HomeMain from "../assets/images/HomeMain.png";
import AboutCircle from "../assets/images/AboutCircle.png";
import AboutMain from "../assets/images/AboutMain.png";
import Mike from "../assets/images/mike.png";
import Linkedin from "../assets/images/linkedin.png";
import Fly from "../assets/images/fly.png";
import Bulb from "../assets/images/bulb.png";
import Shakehand from "../assets/images/shakehand.png";
import Security from "../assets/images/security.png";
import Agreement from "../assets/images/agreement.png";
import Game from "../assets/images/game.png";
import Choose from "../assets/images/choose.png";
import MiniNavbar from "../components/MiniNavbar";
import Counter from "../components/Counter";
import Typical from "react-typical";
import { motion } from "framer-motion";
import GetStartedButtonArrowImage from "../assets/images/get-started-btn-arrow.png";
import Start1 from "../assets/images/start-heo-img-1.png";
import Start2 from "../assets/images/start-heo-img-2.png";
import { Link } from "react-router-dom";
import { Typewriter } from "../utils/motion";

export default function Home() {
  const [isInViewAboutUs, setIsInViewAboutUs] = useState(false);
  const [isInViewAboutStackHolders, setIsInViewAboutStackHolders] =
    useState(false);
  const [isInViewKeyDifferences, setIsInViewKeyDifferences] = useState(false);
  const [isInViewWhyChooseUsView, setIsInViewWhyChooseUsView] = useState(false);

  // Scroll event to track visibility
  const handleScroll = () => {
    // About Us Section
    const aboutUsElement = document.getElementById("about-us-section");
    const aboutUsPosition = aboutUsElement.getBoundingClientRect();
    // if (
    //   aboutUsPosition.top >= 0 &&
    //   aboutUsPosition.bottom <= window.innerHeight
    // ) {
    //   setIsInViewAboutUs(true);
    // }
    if (aboutUsPosition.top >= 200) {
      setIsInViewAboutUs(true);
    }

    // Stack Holder About Section
    const aboutStckHoldersElement = document.getElementById(
      "about-us-inro-section"
    );
    const aboutStckHoldersPosition =
      aboutStckHoldersElement.getBoundingClientRect();
    if (aboutStckHoldersPosition.top <= 600) {
      setIsInViewAboutStackHolders(true);
    }

    // Our Differentiations Section
    const ourdifferentiationsElement = document.getElementById(
      "our-differentiations-section"
    );
    const ourdifferentiationsPostition =
      ourdifferentiationsElement.getBoundingClientRect();
    if (ourdifferentiationsPostition.top <= 600) {
      setIsInViewKeyDifferences(true);
    }

    const whyChooseUsSectionElement = document.getElementById(
      "why-choose-us-section"
    );
    const whyChooseUsSectionPosition =
      whyChooseUsSectionElement.getBoundingClientRect();
    if (whyChooseUsSectionPosition.top <= 600) {
      setIsInViewWhyChooseUsView(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [hideCursor, setHideCursor] = useState(false);
  const text = "Setting New Standards".split(" ");
  return (
    <div>
      <MiniNavbar />
      <MainNavbar />
      {/* Main Section */}
      <div className="main-hero-section">
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="hero-details-box">
                <h1 className="title">
                  <Typewriter
                    text="Setting New Standards" />
                </h1>
                <h2 className="sub-title">
                  in Sports and Entertainment with AI-Driven Experiences 
                </h2>
                <p className="des">
                  Flyzone specializes in delivering IT solutions tailored to the
                  unique needs of the sports and entertainment industry. Our
                  AI-driven solutions enable businesses to improve audience
                  engagement, streamline operations, and strengthen security. By
                  integrating advanced systems and innovative strategies, we
                  help organizations overcome challenges, adapt to change, and
                  achieve growth opportunities in a highly competitive
                  landscape.
                </p>
                <Link to="/events/wahoo">
                  <button className="btn">
                    <span className="me-3">Get Started</span>
                    <img src={GetStartedButtonArrowImage} alt="" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="img-box">
                <motion.img
                  src={HomeMain}
                  className="main-img"
                  alt="Fish"
                  initial={{ x: "400%" }}
                  animate={{ x: "0%" }}
                  transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    duration: 5,
                    delay: 1,
                  }}
                />
                <motion.img
                  src={Start1}
                  alt="Get Started Buttone Arrow"
                  className="start-1"
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                  animate={{
                    x: ["0px", "10px", "0px"],
                  }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 2,
                    ease: "easeInOut",
                  }}
                />
                <motion.img
                  src={Start1}
                  alt="Get Started Buttone Arrow"
                  className="start-2"
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                  animate={{
                    x: ["0px", "10px", "0px"],
                  }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 2,
                    ease: "easeInOut",
                  }}
                />
                <motion.img
                  src={Start2}
                  alt="Get Started Buttone Arrow"
                  className="start-3"
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                  animate={{
                    x: ["0px", "10px", "0px"],
                  }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 2,
                    ease: "easeInOut",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rating-section">
        <div className="container">
          <div className="d-flex justify-content-between text-center flex-column flex-lg-row">
            <div>
              <Counter targetNumber={970} duration={2000} />
              <p>Visitors</p>
            </div>
            <div>
              <Counter targetNumber={30000} duration={2000} />
              <p>Clients</p>
            </div>
            <div>
              <Counter targetNumber={398} duration={2000} />
              <p>Partners</p>
            </div>
            <div>
              <Counter targetNumber={73870} duration={2000} />
              <p>Awards</p>
            </div>
          </div>
        </div>
      </div>
      {/* About Section */}
      <div className="about-us-section" id="about-us-section">
        <div className="container my-5">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="about-details-box">
                <h1 className="title">
                <Typewriter
                    text="About Our Company" />
                </h1>
                <p className="des">
                  Every great performance starts with the right foundation. At
                  Flyzone, we provide the technology that powers excellence in
                  sports and entertainment. With a clear understanding of the
                  industry’s fast-paced nature, we design IT solutions that
                  simplify operations, elevate experiences, and ensure lasting
                  impact.
                </p>
                <p className="des">
                  From AI-driven systems that optimize decision-making to secure
                  platforms that build trust, our work is grounded in one goal:
                  to help your business achieve more.
                </p>
                <div className="d-flex flex-column gap-3 my-2 about-list">
                  <div className="d-flex gap-2 ">
                    <img src={AboutCircle} alt="" width="35px" />
                    <span className="align-self-center">
                      Operational Optimization
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <img src={AboutCircle} alt="" width="35px" />

                    <span className=" align-self-center">
                      Custom-Fit Strategies
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <img src={AboutCircle} alt="" width="40" />
                    <span className=" align-self-center">Tech Expertise</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="img-box">
                <motion.img
                  src={AboutMain}
                  alt="Fish"
                  className="main-img"
                  initial={{ x: "400%" }}
                  animate={{
                    x: isInViewAboutUs ? "0%" : "400%",
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    duration: 5,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us-inro-section" id="about-us-inro-section">
        <div className="container">
          <h1 className="main-title">
            About <span>Us</span>
          </h1>
          <div className="row">
            <motion.div
              initial={{ x: "-200%" }}
              animate={{
                x: isInViewAboutStackHolders ? "0%" : "-200%",
              }}
              className="col-sm-12 mb-5"
              transition={{
                duration: 2,
                ease: "easeInOut",
              }}
            >
              <div className="team-container">
                <div className="team-detail-container">
                  <div className="d-flex flex-row justify-content-start align-items-center mb-2">
                    <h2 className="title">Robert "Fly" Navarro</h2>
                    <a
                      href="https://www.linkedin.com/in/flynavarro/"
                      target="_blank"
                      className="me-5"
                      rel="noreferrer"
                    >
                      <img
                        src={Linkedin}
                        className="linkedin-icon"
                        alt="LinkedIn Logo"
                      />
                    </a>
                  </div>
                  <p>
                    With over 30 years of experience in the fishing industry,
                    Robert Fly has brought his expertise into a new chapter with
                    FlyZone. Known for creating impactful sporting experiences
                    across six continents, he is now combining his knowledge
                    with advanced AI technology to transform the sports and
                    entertainment industry.
                  </p>
                  <p>
                    FlyZone focuses on improving event management, audience
                    engagement, and business connectivity through innovative
                    solutions, setting the standard for how sports and
                    entertainment should be managed, ensuring every experience
                    is smarter, smoother, and more impactful.
                  </p>
                  <p>
                    By blending traditional sporting expertise with modern
                    technology, Robert and Mike are creating new opportunities
                    for partners and stakeholders, setting a new standard in the
                    industry. Their shared vision is raising the bar in the
                    management and experience of sports and entertainment.
                  </p>
                </div>
                <div className="team-img-container">
                  <img src={Fly} alt="mike" width="300" />
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: "200%" }}
              animate={{
                x: isInViewAboutStackHolders ? "0%" : "200%",
              }}
              className="col-sm-12"
              transition={{
                duration: 2,
                ease: "easeInOut",
                delay: 2,
              }}
            >
              <div className="team-container">
                <div className="team-img-container">
                  <img src={Mike} alt="mike" width="300" />
                </div>
                <div className="team-detail-container">
                  <div className="d-flex flex-row justify-content-start align-items-center mb-2">
                    <h2 className="title">Mike Callahan</h2>
                    <a
                      href="https://www.linkedin.com/in/mikecallahanjr/"
                      target="_blank"
                      className="me-5"
                      rel="noreferrer"
                    >
                      <img
                        src={Linkedin}
                        className="linkedin-icon"
                        alt="LinkedIn Logo"
                      />
                    </a>
                  </div>
                  <p>
                    With years of experience in technology and business, Mike
                    Callahan knows how to bring the right tools to the table and
                    turn ideas into action. His expertise in building strong
                    teams and creating winning strategies drives FlyZone’s
                    mission to reshape the sports and entertainment industry
                    through smart, AI-driven solutions.
                  </p>
                  <p>
                    Whether it’s simplifying venue operations, boosting
                    security, or enhancing audience experiences, Mike has a
                    knack for tackling challenges head-on and turning roadblocks
                    into stepping stones. He believes in solutions that don’t
                    just work but leave a lasting impact.
                  </p>
                  <p>
                    But FlyZone’s story is about more than just technology—it’s
                    about a partnership built to last. For over 45 years, Mike
                    and Robert Fly have shared a friendship that’s the heartbeat
                    of the company. Their collaboration isn’t just about
                    business; it’s about trust, shared vision, and creating a
                    legacy that stands the test of time.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      {/* Difference-section */}
      <div className="difference-section" id="our-differentiations-section">
        <div className="container">
          <h1 className="title">
            Our Key <span>Differentiators</span>
          </h1>
          <p className="des">
            At Flyzone, we deliver IT solutions that tackle your most complex
            challenges and drive meaningful results. Trusted by some of the
            biggest names in the sports and entertainment industry, we harness
            the power of data and AI technology to expand audience reach and
            foster long-term engagement. From financial management to AI-powered
            insights and seamless audience interactions, our solutions make your
            daily operations smarter and more efficient.
          </p>
          <div className="row key-difference-row">
            <div className="col-lg-7 col-md-12 order-lg-1 order-2">
              <div className="detail-container">
                <motion.div
                  initial={{ x: "-400%" }}
                  animate={{
                    x: isInViewKeyDifferences ? "0%" : "-400%",
                  }}
                  className="detail-row"
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 0,
                  }}
                >
                  <div className="detail-img">
                    <img src={Bulb} alt="" />
                  </div>
                  <div className="detail-content">
                    <h6>Solutions That Fit Your Goals</h6>
                    <p>
                      At Flyzone, we know that every business has unique goals.
                      That's why our solutions are tailored to your
                      needs—whether it's enhancing audience engagement with
                      advanced tools or streamlining backend processes for
                      greater efficiency, we design strategies that align with
                      your objectives.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: "-400%" }}
                  animate={{
                    x: isInViewKeyDifferences ? "0%" : "-400%",
                  }}
                  className="detail-row"
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 0.5,
                  }}
                >
                  <div className="detail-img">
                    <img src={Security} alt="" />
                  </div>
                  <div className="detail-content">
                    <h6>Partnerships That Power Growth</h6>
                    <p>
                      Our partnerships with industry leaders bring the latest
                      tools and technologies directly to your business. This
                      collaboration empowers you to stay agile, embrace
                      innovation faster, and strengthen your position in a
                      competitive market.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: "-400%" }}
                  animate={{
                    x: isInViewKeyDifferences ? "0%" : "-400%",
                  }}
                  className="detail-row"
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 1,
                  }}
                >
                  <div className="detail-img">
                    <img src={Shakehand} alt="" />
                  </div>
                  <div className="detail-content">
                    <h6>Your Security, Our Priority</h6>
                    <p>
                      Data breaches and system vulnerabilities cost more than
                      money—they erode trust. With Flyzone, your business is
                      protected by robust security protocols that evolve with
                      the threats, keeping your operations safe and reliable.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: "-400%" }}
                  animate={{
                    x: isInViewKeyDifferences ? "0%" : "-400%",
                  }}
                  className="detail-row"
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 1.5,
                  }}
                >
                  <div className="detail-img">
                    <img src={Agreement} alt="" />
                  </div>
                  <div className="detail-content">
                    <h6>Multilingual Support</h6>
                    <p>
                      Whether your audience is local or global, our multilingual
                      support ensures seamless communication at every
                      touchpoint. We help you connect without limitations,
                      opening new doors to growth.
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 order-lg-2 order-1">
              <div className="img-box">
                <img src={Game} alt="fish" className="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose-us-section" id="why-choose-us-section">
        <div className="container my-5">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="img-box">
                <motion.img
                  src={Choose}
                  alt="Fish"
                  className="main-img"
                  initial={{ x: "-400%" }}
                  animate={{
                    x: isInViewWhyChooseUsView ? "0%" : "-400%",
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    duration: 5,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="details-box">
                <h1 className="title">
                <Typewriter
                    text="Why Choose FlyZone" />
                </h1>
                <p className="des">
                  Choosing the right technology partner can make all the
                  difference. At Flyzone, we combine industry expertise with
                  innovative IT solutions tailored for the sports and
                  entertainment industry. With expertise in AI, data analytics,
                  and cloud systems, we streamline operations, enhance audience
                  engagement, and ensure robust security.
                </p>
                <p className="des">
                  At Flyzone, it’s not just about keeping up—it’s about giving
                  you the tools to lead, innovate, and succeed in a rapidly
                  evolving industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetService />
      <Footer />
    </div>
  );
}
