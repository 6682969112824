import React, { useState, useEffect } from "react";
import "../assets/css/dashboard.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import MiniNavbar from "../components/MiniNavbar";
import Tick_Blue_Icon from "../assets/images/tick-blue-icon.png";

const LeaderBoardAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [tournament, setTournament] = useState("Blue Marline Cove Wahoo Open");
  const [showRecords, setShowRecords] = useState([]);

  const [totalCalcutta, setTotalCalcutta] = useState({
    total_heaviest_10: 0,
    total_daily_heaviest_fish: 0,
    total_daily_aggregate: 0,
    total_overall_heavy_fish: 0,
  });

  const getTournamentRecord = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://flyzone.ai/flyzone_laravel/api/tournaments",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let total_heaviest_10 = 0;
      let total_daily_heaviest_fish = 0;
      let total_daily_aggregate = 0;
      let total_overall_heavy_fish = 0;

      if (response?.data) {
        setShowRecords(response?.data);
      }

      if (response.data) {
        response.data.forEach((record) => {
          record.heaviest_10 && ++total_heaviest_10;
          record.daily_heaviest_fish && ++total_daily_heaviest_fish;
          record.daily_aggregate && ++total_daily_aggregate;
          record.overall_heavy_fish && ++total_overall_heavy_fish;
        });
      }

      setTotalCalcutta({
        total_heaviest_10: total_heaviest_10,
        total_daily_heaviest_fish: total_daily_heaviest_fish,
        total_daily_aggregate: total_daily_aggregate,
        total_overall_heavy_fish: total_overall_heavy_fish,
      });
    } catch (err) {
      toast.error("Something went wrong.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getTournamentRecord();
  }, []);

  const handleDropdownChange = (e) => {
    const value = e.target.options[e.target.selectedIndex].value;
    const name = e.target.name;

    if (name === "touranment") {
      setTournament(value);
    }
  };

  return (
    <>
      <MiniNavbar />
      <MainNavbar />
      <ToastContainer />
      <div className=" w-100">
        <div className="header">
          <div className="textHeading1">
            <h1>Registered Teams</h1>
            <div className="line">
              <svg
                width="442"
                height="46"
                viewBox="0 0 742 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 40C112.118 14.3428 406.682 -21.5773 736 40"
                  stroke="url(#paint0_linear_1006_343)"
                  stroke-width="10.3215"
                  stroke-linecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1006_343"
                    x1="370.999"
                    y1="38.9946"
                    x2="370.999"
                    y2="6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#00B7FE" />
                    <stop offset="1" stop-color="#5823FF" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div className="content-section register-team-page">
          <div className="d-flex">
            <div className="d-flex justify-content-end filter-row w-100">
              <div className="col-md-auto ms-1">
                <select
                  style={{
                    background: "#188ffe",
                    color: "#fff",
                  }}
                  id="dropdown"
                  className="form-select custom-select"
                  onChange={handleDropdownChange}
                  name="touranment"
                  value={tournament}
                >
                  <option
                    value="Blue Marline Cove Wahoo Open"
                    style={{
                      background: "white",
                      color: "black",
                    }}
                  >
                    Blue Marline Cove Wahoo Open
                  </option>
                  <option
                    value="Blue Marline Cove Championship"
                    style={{
                      background: "white",
                      color: "black",
                    }}
                  >
                    Blue Marline Cove Championship
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="registered_team_container mt-2">
            {loading ? (
              <div className="loading-overlay">
                <Spinner animation="border" role="status" className="spinner">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <table className="table registered-team-table m-0">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Team Name</th>
                    <th scope="col">Captain Name</th>
                    <th scope="col">Boat Name</th>
                    <th scope="col">Heaviest 10</th>
                    <th scope="col">Daily Heaviest Fish</th>
                    <th scope="col">Daily Aggregate</th>
                    <th scope="col">Overall Heaviest Fish</th>
                  </tr>
                </thead>
                <tbody>
                  {showRecords.length > 0 ? (
                    showRecords.map((record, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{record.team_name || "N/A"}</td>
                        <td>{record.captain_name || "N/A"}</td>
                        <td>{record.boat_name || "N/A"}</td>
                        <td>
                          {record.heaviest_10 && (
                            <img src={Tick_Blue_Icon} alt="" />
                          )}
                        </td>
                        <td>
                          {record.daily_heaviest_fish && (
                            <img src={Tick_Blue_Icon} alt="" />
                          )}
                        </td>
                        <td>
                          {record.daily_aggregate && (
                            <img src={Tick_Blue_Icon} alt="" />
                          )}
                        </td>
                        <td>
                          {record.overall_heavy_fish && (
                            <img src={Tick_Blue_Icon} alt="" />
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )}
                  <tr className="total-row">
                    <th scope="row">Total</th>
                    <td colSpan="3"></td>
                    <td>{totalCalcutta.total_heaviest_10}</td>
                    <td>{totalCalcutta.total_daily_heaviest_fish}</td>
                    <td>{totalCalcutta.total_daily_aggregate}</td>
                    <td>{totalCalcutta.total_overall_heavy_fish}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LeaderBoardAdmin;
